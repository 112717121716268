var menu = new Vue({
	el: '#menu',
	data: {
		isActive: false,
	},
	
	computed: {
	
	},
	methods:{
		
	}
});

var carouselPartners = new Vue({
	el: '#carousel-partners',
	data: {
		
	},
	components:{ 
		'carousel': VueCarousel.Carousel,
		'slide': VueCarousel.Slide
	},
	computed: {
	
	},
	methods:{
		
	}
});

var selectCategory = new Vue({
	el: '#select-category',
	data: {
		
	},
	computed: {
	
	},
	methods: {
		onChange(event) {
			window.location.href = event.target.value;
		}
	}
});

/* Lock / release scroll */
function lockScroll() {
	let scrollTop = document.querySelector('html').scrollTop ? document.querySelector('html').scrollTop : document.querySelector('body').scrollTop;
	document.querySelector('html').classList.add('noscroll');
	document.querySelector('html').style.top = -scrollTop+'px';
}
function releaseScroll() {
	let scrollTop = parseInt(document.querySelector('html').style.top);
	document.querySelector('html').classList.remove('noscroll');
	document.querySelector('html,body').scrollTop = -scrollTop;
}


/* TEAM ITEMS */

//open
const teamItems = document.querySelectorAll('.js-team-item');
Array.from(teamItems).forEach(function(teamItem) {
	teamItem.onclick = function() {
		this.classList.add('active');
		lockScroll()
	}
});

//close
const closeTeamItems = document.querySelectorAll('.js-close-team-item');
Array.from(closeTeamItems).forEach(function(closeTeamItem) {
	closeTeamItem.onclick = function(e) {
		e.stopPropagation();
		releaseScroll()
		closeTeamItem.closest('.js-team-item').classList.remove('active');
	}
});

//filter

const filterTeamButtons = document.querySelectorAll('.js-filter-team');
Array.from(filterTeamButtons).forEach(function(filterTeamButton) {
	filterTeamButton.onclick = function() {
		let activeButton = document.querySelector('.js-filter-team.active');
		activeButton.classList.remove('active');
		filterTeamButton.classList.add('active');
		let filterTeam = this.dataset.filter;
		Array.from(teamItems).forEach(function(teamItem) {
			if( filterTeam == '' ){
				teamItem.style.display = "block";
			}
			else{
				teamItem.style.display = "none";
				if( teamItem.dataset.cat == filterTeam ){
					teamItem.style.display = "block";
				}
			}
		});
	}
});




//list masonry
const gridList = document.querySelector('.masonry-grid');
if(typeof(gridList) != 'undefined' && gridList != null){
	if( window.innerWidth > 480 ){
		imagesLoaded( document.querySelector('.masonry-grid'), function( instance ) {
			console.log('all images are loaded');
			let msnry = new Masonry( gridList, {
				itemSelector: '.masonry-item',
				gutter: 40
			});
		});
	}
}


